// Core Imports
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import { sensorEventUpdate } from "./BottomMenu"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
)
const demoActivities = {
  "spark.spatial_span": "boxgame",
  "spark.cats_and_dogs": "catsndogs",
  "Dot Touch": "dottouch",
  "spark.jewels_a": "jewelspro",
  "spark.jewels_b": "jewelspro",
  "spark.dbt_diary_card": "dbtdiarycard",
  "spark.balloon_risk": "balloonrisk",
  "spark.pop_the_bubbles": "popthebubbles",
  "spark.journal": "journal",
  "spark.breathe": "breathe",
  "spark.recording": "voicerecording",
  "spark.survey": "survey",
  "spark.scratch_image": "scratchimage",
  "spark.tips": "tips",
  "spark.goals": "goals",
  "spark.medications": "medicationtracker",
  "spark.memory_game": "memorygame",
  "group.survey.question": "survey",
}

export default function EmbeddedActivity({ participant, activity, name, onComplete, noBack, tab, ...props }) {
  const classes = useStyles()
  const [embeddedActivity, setEmbeddedActivity] = useState<string>("")
  const [iFrame, setIframe] = useState(null)
  const [settings, setSettings] = useState(null)
  const [saved, setSaved] = useState(false)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const { t, i18n } = useTranslation()
  const [currentActivity, setCurrentActivity] = useState(null)
  const [activityTimestamp, setActivityTimestamp] = useState(0)
  const [timestamp, setTimestamp] = useState(null)
  const [openNotImplemented, setOpenNotImplemented] = useState(false)

  useEffect(() => {
    setCurrentActivity(activity)
    setSaved(true)
    setEmbeddedActivity("")
    setSettings(null)
    setActivityTimestamp(0)
    setIframe(null)
  }, [activity])

  useEffect(() => {
    if (currentActivity !== null && !!currentActivity?.spec) {
      setLoading(true)
      activateEmbeddedActivity(currentActivity)
    }
  }, [currentActivity])

  const handleSaveData = (e) => {
    if (currentActivity !== null && !saved) {
      if (e.data === null) {
        setSaved(true)
        onComplete(null)
        setLoading(false)
      } else if (!saved && currentActivity?.id !== null && currentActivity?.id !== "") {
        try {
          // TODO: what causes this?
          let data = JSON.parse(e.data) //FIXME: seems to fail sometimes, why?
          if (!!data["timestamp"]) {
            setLoading(true)
            delete data["activity"]
            delete data["timestamp"]
            data["activity"] = currentActivity.id
            data["timestamp"] = activityTimestamp
            data["duration"] = new Date().getTime() - activityTimestamp
            data["data_type"] = "survey"
            setData(data)
            setEmbeddedActivity(undefined)
            setSettings(null)
          } else {
            setSaved(true)
            onComplete(null)
            setLoading(false)
          }
        } catch (err) {}
      }
    }
  }

  useEffect(() => {
    if (iFrame != null) {
      iFrame.onload = function () {
        iFrame.contentWindow.postMessage(settings, "*")
      }
      var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent"
      var eventer = window[eventMethod]
      var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message"
      // Listen to message from child window
      eventer(messageEvent, handleSaveData, false)
      return () => window.removeEventListener(messageEvent, handleSaveData)
    }
  }, [iFrame])

  useEffect(() => {
    try {
      if (embeddedActivity === undefined && data !== null && !saved && !!currentActivity) {
        if (!!activityTimestamp && (activityTimestamp ?? 0) !== timestamp) {
          setTimestamp(activityTimestamp)
          sensorEventUpdate(
            tab?.toLowerCase() ?? null,
            participant?.id ?? participant,
            currentActivity.id,
            activityTimestamp
          )
          setCurrentActivity(null)
          SPARK.ActivityEvent.create(participant?.id ?? participant, data)
            .catch((e) => {
              console.dir(e)
            })
            .then((x) => {
              localStorage.setItem("first-time-" + (participant?.id ?? participant) + "-" + currentActivity.id, "true")
              setSaved(true)
              onComplete(data)
              setLoading(false)
            })
        } else {
          onComplete(null)
          setLoading(false)
        }
      } else if (embeddedActivity !== undefined) {
        setActivityTimestamp(new Date().getTime())
        setSaved(false)
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      setOpenNotImplemented(true)
    }
  }, [embeddedActivity, data])

  const activateEmbeddedActivity = async (activity) => {
    let response = "about:blank"
    const exist = localStorage.getItem("first-time-" + (participant?.id ?? participant) + "-" + currentActivity?.id)
    try {
      setSaved(false)
      setSettings({
        ...settings,
        activity: currentActivity,
        configuration: { language: i18n.language },
        autoCorrect: !(exist === "true"),
        noBack: noBack,
      })
      const spec = currentActivity.spec.replace(".hidden", "")
      let activitySpec = await SPARK.ActivitySpec.view(spec)
      if (activitySpec?.executable?.startsWith("data:")) {
        response = atob(activitySpec.executable.split(",")[1])
      } else if (activitySpec?.executable?.startsWith("https:")) {
        response = atob(await (await fetch(activitySpec.executable)).text())
      } else {
        response = await loadFallBack()
      }
      setEmbeddedActivity(response)
      setLoading(false)
    } catch (e) {
      response = await loadFallBack()
      setEmbeddedActivity(response)
      setLoading(false)
    }
  }

  const loadFallBack = async () => {
    if (!!demoActivities[currentActivity.spec]) {
      const baseUrl =
        (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
      let activityURL = baseUrl + "/spark-activities/dist/out"
      return atob(await (await fetch(`${activityURL}/${demoActivities[currentActivity.spec]}.html.b64`)).text())
    } else {
      return "about:blank"
    }
  }

  return (
    <div style={{ display: "flex", width: "100%", height: "93.3vh" }}>
      {embeddedActivity !== "" && (
        <iframe
          ref={(e) => {
            setIframe(e)
          }}
          style={{ flexGrow: 1, border: "none", margin: 0, padding: 0 }}
          allow="accelerometer; ambient-light-sensor; autoplay; battery; camera; display-capture; geolocation; gyroscope; magnetometer; microphone; oversized-images; sync-xhr; usb; wake-lock;X-Frame-Options"
          srcDoc={embeddedActivity}
          sandbox="allow-forms allow-same-origin allow-scripts allow-popups allow-top-navigation "
        />
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openNotImplemented}
        onClose={() => setOpenNotImplemented(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>{t("An exception occured. The data could not be submitted.")}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setLoading(false)
              setOpenNotImplemented(false)
              history.back()
            }}
            color="primary"
          >
            {`${t("Ok")}`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
