// Core Imports
import { Box, Grid, Icon, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import schema from "../../adaptable/theme.json"
import { ReactComponent as SurveyIcon } from "../../icons/app/file-02.svg"
import ActivityPopup from "./ActivityPopup"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    headerTitle: {
      textTransform: "capitalize",
      paddingTop: 0,
      fontWeight: 500,
      marginTop: 20,
      marginBottom: 8,
    },
    blankMsg: {
      marginBottom: "15px",
      marginTop: "5px",
      "& path": { fill: "#666" },
      "& p": { margin: "2px 5px" },
    },
    surveyCard: {
      background: "#fff",
      margin: "10px 10px 10px 10px",
      borderRadius: 8,
      height: 178,
      padding: 20,
      border: "1px solid #eaecf0",
    },
    cardContainer: {
      height: "100%",
      display: "grid",
      flexDirection: "column",
      alignItems: "center",
    },
    iconBox: {
      marginLeft: "auto",
      marginRight: "auto",
      height: 40,
      width: 44,
      padding: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
      alignSelf: "end",
      textAlign: "center",
    },
    icon: {
      width: 22,
      height: 22,
      color: "#fff",
    },
    descriptionTitle: {
      fontSize: "1.0rem",
      textAlign: "center",
      marginBottom: 20,
    },
  })
)

export default function AssessSurveyList({ type, savedActivities, tag, participant, showStreak, ...props }) {
  const classes = useStyles()
  const [activity, setActivity] = useState(null)
  const [open, setOpen] = useState(false)
  const [questionCount, setQuestionCount] = React.useState(0)
  const { t } = useTranslation()

  const openActivities = savedActivities.filter((a) => !a.done)
  const completedActivities = savedActivities.filter((a) => a.done)

  const handleClickOpen = (y: any) => {
    window.location.href = `/#/participant/${participant?.id}/activity/${y?.id}?mode=dashboard`
    // SPARK.Activity.view(y.id).then((data) => {
    //   setActivity(data)
    //   setOpen(true)
    //   y.spec === "spark.dbt_diary_card"
    //     ? setQuestionCount(7)
    //     : y.spec === "spark.survey"
    //     ? setQuestionCount(data.settings?.length ?? 0)
    //     : setQuestionCount(0)
    // })
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {openActivities?.length
          ? openActivities.map((activity) => (
              <SurveyCard
                activity={activity}
                participantId={participant.id}
                handleClick={handleClickOpen}
                done={false}
              />
            ))
          : type !== "Portal" && (
              <Box display="flex" className={classes.blankMsg} ml={1}>
                <Icon>info</Icon>
                <p>{`${t("There are no open activities available.")}`}</p>
              </Box>
            )}
      </Grid>

      <Typography variant="h5" className={classes.headerTitle}>
        {"Completed Survey"}
      </Typography>

      <Grid container spacing={2}>
        {completedActivities?.length
          ? completedActivities.map((activity) => (
              <SurveyCard
                activity={activity}
                participantId={participant.id}
                handleClick={handleClickOpen}
                done={true}
              />
            ))
          : type !== "Portal" && (
              <Box display="flex" className={classes.blankMsg} ml={1}>
                <Icon>info</Icon>
                <p>{`${t("There are no completed activities available.")}`}</p>
              </Box>
            )}
      </Grid>

      <ActivityPopup
        activity={activity}
        tag={tag}
        questionCount={questionCount}
        open={open}
        onClose={() => setOpen(false)}
        type={type}
        showStreak={showStreak}
        participant={participant}
      />
    </Box>
  )
}

const SurveyCard = ({ activity, handleClick, participantId, done, ...props }) => {
  const classes = useStyles()

  // TODO: if scheduled activity, show time left in card
  return (
    <Grid
      item
      xs={5}
      className={classes.surveyCard}
      onClick={() => {
        console.log("hallo")
        handleClick(activity)
      }}
    >
      <Box className={classes.cardContainer}>
        <Box
          className={classes.iconBox}
          style={{ background: done ? schema.palette.primary.success : schema.palette.primary.main }}
        >
          {<SurveyIcon className={classes.icon} />}
        </Box>
        <Typography variant="h5" className={classes.descriptionTitle}>
          {activity.name}
        </Typography>
      </Box>
    </Grid>
  )
}
