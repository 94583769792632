// Core Imports
import {
  Backdrop,
  Box,
  CircularProgress,
  Slide,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
// Local Imports
import { useTranslation } from "react-i18next"
import SPARK, { Participant as ParticipantObj } from "spark-core"
import schema from "../../adaptable/theme.json"
import locale_lang from "../../locale_map.json"
import { Service } from "../DBService/DBService"
import ResponsiveDialog from "../ResponsiveDialog"
import Welcome from "../Welcome"
import BottomMenu from "./BottomMenu"
import Home from "./Home"
import Streak from "./Streak"
import Settings from "./Settings"

export async function getImage(activityId: string, spec: string) {
  return [
    await SPARK.Type.getAttachment(
      activityId,
      spec === "spark.survey" ? "spark.dashboard.survey_description" : "spark.dashboard.activity_details"
    ),
  ].map((y: any) => (!!y.error ? undefined : y.data))[0]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scroll: { overflowY: "hidden" },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    MuiDialogPaperScrollPaper: {
      maxHeight: "100% !important",
    },
  })
)

function _hideCareTeam() {
  return (SPARK.Auth._auth.serverAddress || "").includes(".psych.digital")
}
function _patientMode() {
  return SPARK.Auth._type === "participant"
}
async function getShowWelcome(participant: ParticipantObj): Promise<boolean> {
  if (!_patientMode()) return false
  let _hidden = (await SPARK.Type.getAttachment(participant.id, "spark.dashboard.welcome_dismissed")) as any
  return !!_hidden.error ? true : !(_hidden.data as boolean)
}
async function setShowWelcome(participant: ParticipantObj): Promise<void> {
  await SPARK.Type.setAttachment(participant.id, "me", "spark.dashboard.welcome_dismissed", true)
}

async function tempHideCareTeam(participant: ParticipantObj): Promise<boolean> {
  if (_hideCareTeam()) return true
}

async function addHiddenEvent(
  participant: ParticipantObj,
  timestamp: number,
  activityName: string
): Promise<string[] | undefined> {
  let _hidden = (await SPARK.Type.getAttachment(participant.id, "spark.dashboard.hidden_events")) as any
  let _events = !!_hidden.error ? [] : _hidden.data
  if (_events.includes(`${timestamp}/${activityName}`)) return _events
  let new_events = [..._events, `${timestamp}/${activityName}`]
  let _setEvents = (await SPARK.Type.setAttachment(
    participant.id,
    "me",
    "spark.dashboard.hidden_events",
    new_events
  )) as any
  if (!!_setEvents.error) return undefined
  return new_events
}
// Refresh hidden events list.
async function getHiddenEvents(participant: ParticipantObj): Promise<string[]> {
  let _hidden = (await SPARK.Type.getAttachment(participant.id, "spark.dashboard.hidden_events")) as any
  return !!_hidden.error ? [] : (_hidden.data as string[])
}

export async function getEvents(participant: any, activityId: string) {
  let activityEvents = await SPARK.ActivityEvent.allByParticipant(participant?.id ?? participant, activityId)
  let dates = []
  let streak = 0
  activityEvents.map((activityEvent, i) => {
    let date = new Date(activityEvent.timestamp)
    if (!dates.includes(date.toLocaleDateString())) {
      dates.push(date.toLocaleDateString())
    }
  })
  let currentDate = new Date()
  for (let date of dates) {
    if (date === currentDate.toLocaleDateString()) {
      streak++
    } else {
      break
    }
    currentDate.setDate(currentDate.getDate() - 1)
  }
  return streak > 0 ? streak : 1
}

export default function Participant({
  participant,
  ...props
}: {
  participant: ParticipantObj
  activeTab: Function
  tabValue: string
  surveyDone: boolean
  submitSurvey: Function
  setShowDemoMessage: Function
  activityId?: string
}) {
  const [activities, setActivities] = useState(null)
  const [streakActivity, setStreakActivity] = useState(null)
  const getTab = () => {
    return props.tabValue
  }

  const [tab, _setTab] = useState(getTab())
  const supportsSidebar = useMediaQuery(useTheme().breakpoints.up("md"))
  const { enqueueSnackbar } = useSnackbar()
  const [openDialog, setOpen] = useState(false)
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [openComplete, setOpenComplete] = React.useState(false)
  const [streak, setStreak] = useState(1)
  const { t, i18n } = useTranslation()

  const tabDirection = (currentTab) => {
    return supportsSidebar ? "up" : "left"
  }

  const getSelectedLanguage = () => {
    const matched_codes = Object.keys(locale_lang).filter((code) => code.startsWith(navigator.language))
    const lang = matched_codes.length > 0 ? matched_codes[0] : "en-US"
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang)
    }
    return i18n.language ? i18n.language : lang ? lang : "en-US"
  }

  useEffect(() => {
    setLoading(true)

    fetchActivities(participant.id).then((personalActivities) => {
      const sortedActivities = personalActivities.sort((a, b) => b.timestamp - a.timestamp)

      setActivities(sortedActivities)

      // props.activeTab(tab, participant.id)
      let language = !!localStorage.getItem("SPARK_user_" + participant.id)
        ? JSON.parse(localStorage.getItem("SPARK_user_" + participant.id)).language
        : getSelectedLanguage()
        ? getSelectedLanguage()
        : "en-US"
      i18n.changeLanguage(language)
      //  getShowWelcome(participant).then(setOpen)
    })

    const hasSetPermissions = !!participant.permissions && participant.permissions !== null

    if (!hasSetPermissions) {
      activeTab("permissions")
      setLoading(false)
    }
  }, [])

  const fetchActivities = (participant_id): Promise<any[]> => {
    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/participant/${participant_id}/personal`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            reject(res.error)
          } else {
            resolve(res.data)
          }
        })
    })
  }

  useEffect(() => {
    if (activities !== null) {
      Service.getAllTags("activitytags").then((result) => {
        if ((result || []).length == 0) {
          let data = []
          let count = 0
          ;(activities || []).map((activity) => {
            getImage(activity.id, activity.spec).then((img) => {
              data.push({
                id: activity.id,
                category: activity.category,
                spec: activity.spec,
                description: img?.description ?? "",
                photo: img?.photo ?? null,
                streak: img?.streak ?? null,
              })
              if (count === activities.length - 1) {
                Service.addUserData("activitytags", data, true).then(() => {
                  setLoading(false)
                })
              }
              count++
            })
          })
        } else {
          setLoading(false)
        }
      })
    }
  }, [activities])

  const activeTab = (newTab) => {
    props.activeTab(newTab, participant.id)
    _setTab(newTab)
  }

  const showStreak = (participant, activity) => {
    Service.getUserDataByKey("activitytags", [activity?.id], "id").then((tags) => {
      const tag = tags[0]
      setStreakActivity(tag?.streak ?? null)
      if (!!tag?.streak?.streak || typeof tag?.streak === "undefined") {
        getEvents(participant, activity.id).then((streak) => {
          setStreak(streak)
          setOpenComplete(true)
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    })
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {activities !== null && !loading && (
        <Box style={{ height: "100%", background: schema.palette.background }}>
          {tab === "settings" ? (
            <Box mt={1} mb={4}>
              <Settings participant={participant} activeTab={activeTab} />
            </Box>
          ) : (
            <Box mt={1} mb={4}>
              <Home participant={participant} activities={activities} tab={tab} />
            </Box>
          )}

          <BottomMenu
            activeTab={activeTab}
            tabValue={tab}
            participant={participant}
            showWelcome={openDialog}
            setShowDemoMessage={(val) => props.setShowDemoMessage(val)}
          />
          <ResponsiveDialog open={!!openDialog} transient animate fullScreen>
            <Welcome
              activities={activities}
              onClose={() => {
                setOpen(false)
                setShowWelcome(participant)
              }}
            />
          </ResponsiveDialog>
        </Box>
      )}
      <Streak
        open={openComplete}
        onClose={() => {
          setOpenComplete(false)
        }}
        activity={streakActivity}
        streak={streak}
      />
    </>
  )
}
