// Core Imports
import {
  AppBar,
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  TextField,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  colors,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
// Local Imports
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import i18n, { userLanguages } from "../i18n"
import locale_lang from "../locale_map.json"
import { Service } from "./DBService/DBService"
import ModeToggleButton from "./ModeToggleButton"
import { sensorEventUpdate } from "./Participant/BottomMenu"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: 20,
      paddingRight: 20,
      alignItems: "flex-start",
      paddingTop: 15,
      paddingBottom: theme.spacing(1),
      "& h5": {
        color: "#555555",
        fontSize: 25,
        fontWeight: "bold",
        paddingTop: 15,
        // position: "absolute",
        bottom: 0,
        [theme.breakpoints.down("sm")]: {
          marginTop: 30,
        },
      },
    },
    inlineHeader: {
      background: "#FFFFFF",
      boxShadow: "none",

      "& h5": { fontSize: 25, color: "rgba(0, 0, 0, 0.75)", fontWeight: 600 },
    },
    toolbardashboard: {
      minHeight: 75,
      padding: "15px 10px 0",
      [theme.breakpoints.down("xs")]: {
        display: "block",
        width: "100%",
        padding: "0px 10px 0",
      },
    },
    headerRight: {
      "& span": {
        color: "rgba(0, 0, 0, 0.54)",
      },
      [theme.breakpoints.down("xs")]: {
        display: "block",
        float: "right",
      },
    },
    backbtn: {
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },
    thumbContainer: {
      maxWidth: 1055,
      left: 0,
      right: 0,
      position: "absolute",
      height: 55,
      padding: "6px 0px 6px 15px",
      background: "#fff",
      "& h5": { fontWeight: "bold", color: "rgba(0, 0, 0, 0.75)", fontSize: 30 },

      [theme.breakpoints.up("md")]: {
        // paddingLeft: 125,
        width: "80%",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: 15,
      },
    },
    appbarResearcher: { zIndex: 1111, position: "sticky", boxShadow: "none", backgroundColor: "#fff" },
    toolbarResearcher: {
      width: "100%",
      background: "transparent",
      "& h5": {
        padding: "55px 0 25px",
        [theme.breakpoints.down("sm")]: {
          paddingTop: 38,
          paddingBottom: 20,
        },
      },
      outline: "#D0D5DD solid 1px",
    },
    logToolbarResearcher: { marginTop: 50, paddingTop: 0, background: "transparent", "& h5": { paddingTop: 35 } },
    customPopover: { backgroundColor: "rgba(0, 0, 0, 0.4)" },
    customPaper: {
      maxWidth: 380,
      maxHeight: 600,
      marginTop: 25,
      minWidth: 320,
      marginLeft: 15,
      borderRadius: 10,
      padding: "10px 0",
      "& h6": { fontSize: 16, fontWeight: 600 },
      "& li": {
        display: "inline-block",
        width: "100%",
        padding: "15px 30px",
        fontSize: 16,
        fontWeight: 600,
        "&:hover": { backgroundColor: theme.palette.primary.main },
      },
      "& *": { cursor: "pointer" },
    },
    researcherAccount: {
      color: "#000",
      fontSize: 14,
      lineHeight: "38px",
      cursor: "pointer",
      textTransform: "capitalize",
      boxShadow: "none",
      background: "transparent",
      paddingRight: 0,
      "&:hover": { background: "transparent" },
      "&:active": { background: "transparent", boxShadow: "none" },
      "& svg": { marginRight: 10 },
    },
    logResearcherToolbar: {
      background: "#fff",
      position: "fixed",
      width: "100%",
      zIndex: 1,
      minHeight: 50,
      justifyContent: "space-between",
      "& $backbtn": { color: "#fff" },
      outline: "#D0D5DD solid 1px",
    },
    logResearcherBorder: { paddingTop: 100, top: 50 },
    logParticipantBorder: {
      border: theme.palette.primary.main + " solid 5px",
      borderTop: 0,
      paddingTop: 110,
      top: 50,
      height: "calc(100% - 50px)",
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
  })
)

export default function NavigationLayout({
  title,
  id,
  authType,
  noToolbar,
  goBack,
  onLogout,
  activeTab,
  sameLineTitle,
  changeMode,
  setIdentity,
  ...props
}: {
  title?: string
  id?: string
  authType: string
  noToolbar?: boolean
  goBack?: any
  onLogout?: any
  activeTab?: string
  sameLineTitle?: boolean
  changeMode?: Function
  children?: any
  setIdentity?: Function
}) {
  const [showCustomizeMenu, setShowCustomizeMenu] = useState<Element>()
  const [confirmLogout, setConfirmLogout] = useState(false)
  const [passwordChange, setPasswordChange] = useState(false)
  const [openMessages, setOpenMessages] = useState(false)
  const [conversations, setConversations] = useState({})
  const [msgCount, setMsgCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const supportsSidebar = useMediaQuery(useTheme().breakpoints.up("md"))
  const print = useMediaQuery("print")
  const classes = useStyles()
  const { t } = useTranslation()
  //sameLineTitle
  const dashboardMenus = ["Learn", "Manage", "Assess", "Portal", "Feed", "Researcher"]
  const hideNotifications = ["Researcher", "Administrator"]
  const [sensorData, setSensorData] = useState(null)
  const [researcherId, setResId] = useState(null)
  const [selectedLanguage, setSelectedLanguage]: any = useState(
    localStorage.getItem("lang") ? JSON.parse(localStorage.getItem("lang")) : i18n.language
  )
  const [newPassword, setNewPassword]: any = useState(null)
  const [credentials, setCredentials]: any = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    SPARK.Credential.list("me").then((cred) => {
      cred = cred.filter((c) => c.hasOwnProperty("origin"))
      setCredentials(cred)
    })
    let isMounted = true
    if (isMounted) {
      if (
        (authType === "researcher" || authType === "admin") &&
        typeof title != "undefined" &&
        title.startsWith("Participant")
      ) {
        Service.getAll("researcher").then((researcher) => {
          setResId(researcher[0]["id"])
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
      refresh()
    }
    return () => {
      isMounted = false
    }
  }, [])

  const refresh = () => {
    if (!!id && id !== "me") {
      if (sensorData === null) {
        ;(async () => {
          let data = await SPARK.SensorEvent.allByParticipant(id, "spark.analytics")
          data = Array.isArray(data) ? (data || []).filter((d) => d.data.page === "conversations") : null
          setSensorData(!!data ? data[0] : [])
        })()
      }
    }
    if (selectedLanguage !== i18n.language) {
      setSelectedLanguage(i18n.language)
    }
  }

  useEffect(() => {
    if (sensorData !== null && id !== "me") refreshMessages()
  }, [sensorData])

  useEffect(() => {
    if (sensorData !== null && id !== "me") setMsgCount(getMessageCount())
  }, [conversations])

  const updateAnalytics = async () => {
    setSensorData(null)
    await sensorEventUpdate("conversations", id, null)
    let data = await SPARK.SensorEvent.allByParticipant(id, "spark.analytics")
    data = data.filter((d) => d.data.page === "conversations")
    setSensorData(data ? data[0] : [])
    window.location.href = `/#/participant/${id}/messages`
  }

  const refreshMessages = async () => {
    console.log("Fetching messages...")
    setConversations(
      Object.fromEntries(
        (
          await Promise.all(
            [id || ""].map(async (x) => [x, await SPARK.Type.getAttachment(x, "spark.messaging").catch((e) => [])])
          )
        )
          .filter((x: any) => x[1].message !== "404.object-not-found")
          .map((x: any) => [x[0], x[1].data])
      )
    )
  }

  const getMessageCount = () => {
    let x = (conversations || {})[id || ""] || []
    return !Array.isArray(x)
      ? 0
      : x.filter((a) => a.from === "researcher" && new Date(a.date).getTime() > (sensorData?.timestamp ?? 0)).length
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElSettings, setAnchorElSettings] = React.useState(null)

  const handleClickAccount = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseAccount = () => {
    setAnchorEl(null)
  }
  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget)
  }

  const handleCloseSettings = () => {
    setAnchorElSettings(null)
  }

  const navigateToProjects = () => {
    window.location.href = "/#/researcher/me/projects"
  }

  const navigateToUsers = () => {
    if (authType === "admin") {
      window.location.href = `/#/users` //TODO: might need to change this
    } else {
      window.location.href = "/#/researcher/me/users"
    }
  }

  const openAccount = Boolean(anchorEl)
  const openSettings = Boolean(anchorElSettings)
  const idp = openAccount ? "simple-popover" : undefined
  const idpSettings = openSettings ? "simple-popover" : undefined
  const roles = ["Administrator", "User Administrator", "Practice Lead"]
  return (
    <Box>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!!noToolbar || !!print ? (
        <React.Fragment />
      ) : (
        <AppBar classes={{ root: classes.appbarResearcher }}>
          {(authType === "researcher" || authType === "admin") && (
            <Toolbar className={classes.logResearcherToolbar}>
              {typeof title != "undefined" && title.startsWith("Participant") ? (
                <Box>
                  {`${t("Participant View")}`}: {id}
                </Box>
              ) : (
                <Box>
                  <Button
                    variant="text"
                    style={{ width: 150, justifyContent: "flex-start" }}
                    onClick={(event) => (window.location.href = `/#/`)}
                  >
                    <IconButton
                      style={{
                        width: 32,
                        height: 32,
                        borderRadius: 8,
                        outline: 1,
                        outlineColor: "#D0D5DD",
                        outlineStyle: "solid",
                        marginRight: 5,
                      }}
                    >
                      <Icon color="primary">bolt</Icon>
                    </IconButton>
                    <b>{`${t("Spark")}`}</b>
                  </Button>
                  <Button
                    variant={
                      window.location.href.indexOf("participants") < 0 && window.location.href.indexOf("project") > -1
                        ? "contained"
                        : "text"
                    }
                    disableElevation={true}
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      color: "#000",
                      backgroundColor:
                        window.location.href.indexOf("participants") < 0 && window.location.href.indexOf("project") > -1
                          ? "#fafafa"
                          : "#fff",
                    }}
                    onClick={navigateToProjects}
                  >
                    {`${t("Projects")}`}
                  </Button>
                  <Button
                    variant={window.location.href.indexOf("participants") > -1 ? "contained" : "text"}
                    disableElevation={true}
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      color: "#000",
                      backgroundColor: window.location.href.indexOf("participants") > -1 ? "#fafafa" : "#fff",
                    }}
                    onClick={navigateToUsers}
                  >
                    {`${t("Users")}`}
                  </Button>
                </Box>
              )}
              {(authType === "researcher" || authType === "admin") &&
                title !== "Administrator" &&
                title !== "User Administrator" &&
                title !== "Practice Lead" &&
                !title.startsWith("Participant") && (
                  <Box>
                    <IconButton>
                      <Icon>search</Icon>
                    </IconButton>
                    <IconButton onClick={handleClickSettings}>
                      <Icon>settings</Icon>
                    </IconButton>
                    <Popover
                      classes={{ root: classes.customPopover, paper: classes.customPaper }}
                      id={idpSettings}
                      open={openSettings}
                      anchorEl={anchorElSettings}
                      onClose={handleCloseSettings}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {authType === "admin" && (title === "Administrator" || title === "User Administrator") && (
                        <MenuItem onClick={() => setPasswordChange(true)}>{`${t("Manage Credentials")}`}</MenuItem>
                      )}
                      <MenuItem>
                        <TextField
                          select
                          label={`${t("Select Language")}`}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          value={selectedLanguage}
                          onChange={(event) => {
                            localStorage.setItem("lang", JSON.stringify(event.target.value))
                            i18n.changeLanguage(event.target.value)
                            setSelectedLanguage(event.target.value)
                          }}
                          variant="filled"
                        >
                          {Object.keys(locale_lang).map((key, value) => {
                            if (userLanguages.includes(key)) {
                              return (
                                <MenuItem key={key} value={key}>
                                  {locale_lang[key].native + " (" + locale_lang[key].english + ")"}
                                </MenuItem>
                              )
                            }
                          })}
                        </TextField>
                      </MenuItem>
                      <MenuItem>
                        <ModeToggleButton changeMode={changeMode} />
                      </MenuItem>
                    </Popover>
                    <IconButton>
                      <Icon>notifications_none</Icon>
                    </IconButton>
                    <IconButton onClick={handleClickAccount}>
                      <Icon>account_circle</Icon>
                    </IconButton>
                    <Popover
                      classes={{ root: classes.customPopover, paper: classes.customPaper }}
                      id={idp}
                      open={openAccount}
                      anchorEl={anchorEl}
                      onClose={handleCloseAccount}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {authType === "admin" && (title === "Administrator" || title === "User Administrator") && (
                        <MenuItem onClick={() => setPasswordChange(true)}>{`${t("Manage Credentials")}`}</MenuItem>
                      )}
                      <MenuItem divider onClick={() => setConfirmLogout(true)}>
                        {`${t("Logout")}`}
                      </MenuItem>
                      <MenuItem
                        dense
                        onClick={() => {
                          setShowCustomizeMenu(undefined)
                          window.open("https://spark.opens.science/", "_blank")
                        }}
                      >
                        {`${t("Help & Support")}`}
                      </MenuItem>
                      {/* <MenuItem
                        dense
                        onClick={() => {
                          setShowCustomizeMenu(undefined)
                          window.open("https://community.lamp.digital", "_blank")
                        }}
                      >
                        {`${t("SPARK Community")}`}
                      </MenuItem>
                      <MenuItem
                        dense
                        onClick={() => {
                          setShowCustomizeMenu(undefined)
                          window.open("mailto:team@digitalpsych.org", "_blank")
                        }}
                      >
                        {`${t("Contact Us")}`}
                      </MenuItem> */}
                    </Popover>
                  </Box>
                )}
            </Toolbar>
          )}
          {((authType !== "researcher" && authType !== "admin") ||
            ((authType === "researcher" || authType === "admin") && title.startsWith("Participant"))) && (
            <Toolbar
              classes={{
                root:
                  classes.toolbarResearcher +
                  (authType === "researcher" || authType === "admin" ? " " + classes.logToolbarResearcher : ""),
              }}
            >
              {((authType !== "admin" && dashboardMenus.indexOf(activeTab) < 0) || title.startsWith("Participant")) && (
                <Container className={classes.thumbContainer}>
                  <Typography
                    variant="h5"
                    style={{
                      marginLeft: supportsSidebar ? 35 : undefined,
                      textTransform: "capitalize",
                      paddingTop: 0,
                      marginTop: supportsSidebar ? 55 : undefined,
                    }}
                  >
                    {typeof activeTab === "string" ? `${t(activeTab)}` : "Survey"}
                  </Typography>
                </Container>
              )}
              {/* {((authType !== "admin" && !sameLineTitle && activeTab !== "Studies") || title.startsWith("Participant")) && (
                <Container className={classes.thumbContainer}>
                  <Typography
                    variant="h5"
                    style={{
                      textTransform: "capitalize",
                      marginLeft:
                        supportsSidebar && typeof title != "undefined" && title.startsWith("Participant") ? 0 : undefined,
                    }}
                  >
                    {typeof activeTab === "string" ? `${t(activeTab)}` : ""}
                  </Typography>
                </Container>
              )} */}
              <Box flexGrow={1} />
              {typeof title != "undefined" &&
                title.startsWith("Participant") &&
                (supportsSidebar || dashboardMenus.indexOf(activeTab) >= 0) && (
                  <Box className={classes.headerRight}>
                    {hideNotifications.indexOf(activeTab) < 0 ? (
                      //add text inside tooltip field
                      <Tooltip
                        title={
                          SPARK.Auth._auth.id === SPARK.Auth._auth.password
                            ? `${t("Set Password")}`
                            : `${t("Change Password")}`
                        }
                      >
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={() => {
                            setPasswordChange(true)
                          }}
                        >
                          {SPARK.Auth._auth.id === SPARK.Auth._auth.password
                            ? `${t("Set Password")}`
                            : `${t("Change Password")}`}
                        </Button>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </Box>
                )}
              {typeof title != "undefined" &&
                title.startsWith("Participant") &&
                (supportsSidebar || dashboardMenus.indexOf(activeTab) >= 0) && (
                  <Box className={classes.headerRight}>
                    {hideNotifications.indexOf(activeTab) < 0 ? (
                      <Tooltip title={`${t("Notifications")}`}>
                        <Badge
                          badgeContent={msgCount > 0 ? msgCount : undefined}
                          color="primary"
                          onClick={() => {
                            localStorage.setItem("lastTab" + id, JSON.stringify(new Date().getTime()))
                            updateAnalytics()
                          }}
                        >
                          <Icon>comment</Icon>
                        </Badge>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </Box>
                )}
              {typeof title != "undefined" && title.startsWith("Participant") && (
                <Box>
                  <Tooltip title={`${t("Profile & Settings")}`}>
                    <IconButton
                      aria-owns={!!showCustomizeMenu ? "menu-appbar" : null}
                      aria-haspopup="true"
                      onClick={(event) => setShowCustomizeMenu(event.currentTarget)}
                      color="default"
                    >
                      <Icon>account_circle</Icon>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="menu-appbar"
                    anchorEl={showCustomizeMenu}
                    open={!!showCustomizeMenu && !confirmLogout && !passwordChange}
                    onClose={() => setShowCustomizeMenu(undefined)}
                  >
                    <MenuItem disabled divider>
                      <b>{`${t(title)}`}</b>
                    </MenuItem>
                    <MenuItem divider onClick={() => setConfirmLogout(true)}>
                      {`${t("Logout")}`}
                    </MenuItem>
                    <MenuItem
                      dense
                      onClick={() => {
                        setShowCustomizeMenu(undefined)
                        window.open("https://spark.opens.science/", "_blank")
                      }}
                    >
                      <b style={{ color: colors.grey["600"] }}>{`${t("Help & Support")}`}</b>
                    </MenuItem>
                    {/* <MenuItem
                      dense
                      onClick={() => {
                        setShowCustomizeMenu(undefined)
                        window.open("https://community.lamp.digital", "_blank")
                      }}
                    >
                      <b style={{ color: colors.grey["600"] }}>{`${t("SPARK Community")}`}</b>
                    </MenuItem> */}
                    {/* <MenuItem
                      dense
                      onClick={() => {
                        setShowCustomizeMenu(undefined)
                        window.open("mailto:team@digitalpsych.org", "_blank")
                      }}
                    >
                      <b style={{ color: colors.grey["600"] }}>{`${t("Contact Us")}`}</b>
                    </MenuItem> */}
                  </Menu>
                </Box>
              )}
            </Toolbar>
          )}
        </AppBar>
      )}
      <Box
        style={{
          marginTop: 0,
          width: "100%",
          overflowY: "hidden",
          overflow: !!id ? "hidden" : "initial",
        }}
      >
        <Box
          className={
            (authType === "researcher" || authType === "admin") &&
            typeof title != "undefined" &&
            title.startsWith("Participant")
              ? " " + classes.logParticipantBorder
              : authType === "researcher" || authType === "admin"
              ? " " + classes.logResearcherBorder
              : " "
          }
        >
          {props.children}
        </Box>
      </Box>
      <Dialog
        open={!!confirmLogout}
        onClose={() => setConfirmLogout(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`${t(
          "Are you sure you want to log out of SPARK right now?"
        )}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`${t("If you've made some changes, make sure they're saved before you continue to log out.")}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmLogout(false)} color="secondary">
            {`${t("Go Back")}`}
          </Button>
          <Button
            onClick={() => {
              onLogout()
              setConfirmLogout(false)
            }}
            color="primary"
            autoFocus
          >
            {`${t("Logout")}`}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!passwordChange || SPARK.Auth._auth.id === SPARK.Auth._auth.password}
        onClose={() => setPasswordChange(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{`${t("Set new password")}`}</DialogTitle>
        <DialogContent style={{ marginBottom: 12 }}>
          <TextField
            type="password"
            value={newPassword}
            onChange={(event) => {
              setNewPassword(event.target.value)
            }}
          ></TextField>
          <Button
            onClick={() => {
              if (SPARK.Auth._auth.id === newPassword) {
                enqueueSnackbar(`${t("New password cannot be same as username")}`, { variant: "error" })
                console.log("Password cannot be same as old password")
              } else if (newPassword === null || newPassword === "" || newPassword === undefined) {
                enqueueSnackbar(`${t("New password cannot be empty")}`, { variant: "error" })
                console.log("Password cannot be empty")
                setNewPassword(null)
                setPasswordChange(false)
              } else {
                if (
                  !!(SPARK.Credential.update(id, credentials[0].access_key, {
                    secret_key: newPassword,
                    ...credentials,
                  }) as any).error
                ) {
                  enqueueSnackbar(`${t("Error changing password")}`, { variant: "error" })
                  console.log("Error changing password")
                }
                //temp id variable
                let tempId = SPARK.Auth._auth.id
                setIdentity({
                  id: tempId,
                  password: newPassword,
                  serverAddress: SPARK.Auth._auth.serverAddress,
                }).then((res) => {
                  if (res.authType === "participant") {
                    localStorage.setItem("lastTab" + tempId, JSON.stringify(new Date().getTime()))
                    SPARK.SensorEvent.create(tempId, {
                      timestamp: Date.now(),
                      sensor: "spark.analytics",
                      data: {
                        type: "login",
                        device_type: "Dashboard",
                        user_agent: `SPARK-dashboard/${process.env.REACT_APP_GIT_SHA} ${window.navigator.userAgent}`,
                      },
                    } as any)
                  }
                  localStorage.setItem(
                    "SPARK_user_" + tempId,
                    JSON.stringify({
                      language: selectedLanguage,
                    })
                  )
                  ;(async () => {
                    await Service.deleteDB()
                  })()
                })
                SPARK.Auth._auth.password = newPassword
                enqueueSnackbar(`${t("Succesfully changed password")}`, { variant: "info" })
                setNewPassword(null)
                setPasswordChange(false)
              }
            }}
            color="primary"
          >
            {`${t("Set Password")}`}
          </Button>
          {/*<CredentialManager id={!!id ? id : SPARK.Auth._auth.id} type={title} />*/}
        </DialogContent>
      </Dialog>
    </Box>
  )
}
