// Core Imports
import { Backdrop, Box, CircularProgress, Grid, Theme, createStyles, makeStyles } from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import questionTypes, {
  FORM_STRUCTURE_TYPE_KEY,
  QUESTION_GROUP_TYPE_ID,
  QUESTION_TYPE_KEY,
} from "../../../../data/questionTypes"
import { specialCharacters } from "../../../../data/specialChars"
import i18n from "../../../../i18n"
import { Question } from "../../../../types/question"
import AddQuestionForm from "./AddQuestionForm"
import EditQuestionForm from "./EditQuestionForm"
import { validateExpression } from "./ExpressionMethods"
import QuestionItemSettings from "./QuestionItemSettings"
import QuestionsList from "./QuestionsList"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerWidth: { maxWidth: 1055, marginBottom: "10px" },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    "@global": {
      body: {
        backgroundColor: "#fff",
      },
    },
    errorcustom: {
      "& span": { color: "#f44336", minWidth: "56px" },
      "& h6": { fontSize: "1.25rem", fontWeight: "normal" },
    },
  })
)

export default function GameCreator({
  activities,
  value,
  activitySpecId,
  study,
  ...props
}: {
  activities?: any
  value?: any
  activitySpecId?: string
  study?: any
}) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()

  const [showQuestionTypePicker, setShowQuestionTypePicker] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState(null)

  const [data, setData] = useState({
    id: value?.id ?? undefined,
    name: value?.name ?? "",
    spec: value?.spec ?? activitySpecId,
    schedule: !!value ? value?.schedule : [],
    description: value?.description ?? "",
    settings: !!value ? value.settings : {},
    studyID: !!value ? value.study_id : study.id,
    category: value?.category ?? null,
  })

  const [questions, setQuestions] = useState(!!value ? value.settings : [])

  const validateQuestions = (questions) => {
    let status = 0
    if (!!questions && questions.length > 0) {
      let optionsArray = []
      {
        ;(questions || []).map((x, idx) => {
          questions[idx].type === "list" ||
          questions[idx].type === "multiselect" ||
          questions[idx].type === "slider" ||
          questions[idx].type === "rating"
            ? !Array.isArray(questions[idx].options) ||
              questions[idx].options === null ||
              (!!questions[idx].options && questions[idx].options.length === 0)
              ? optionsArray.push(1)
              : (questions[idx].options || []).filter(
                  (i) =>
                    (!!i &&
                      (((questions[idx].type === "slider" || questions[idx].type === "rating") && i?.value >= 0) ||
                        ((questions[idx].type === "list" || questions[idx].type === "multiselect") &&
                          (i?.value === 0 ||
                            i?.value === "0" ||
                            (i?.value !== 0 &&
                              i?.value !== "0" &&
                              ((i?.value || "").toString() || "")?.trim().length > 0))))) ||
                    i === ""
                ).length === (questions[idx].options || []).length
              ? optionsArray.push(0)
              : optionsArray.push(1)
            : optionsArray.push(0)
        })
      }
      if (optionsArray.filter((val) => val !== 0).length > 0) {
        status = 1
        return false
      } else {
        status = 0
      }
    }
    if (
      questions.length === 0 ||
      questions.filter((val) => !!val.text && val.text?.trim().length !== 0).length !== questions.length
    ) {
      return false
    } else if (
      questions.filter((q) => ["list", "multiselect", "slider", "rating", "time"].includes(q.type)).length > 0 &&
      status === 1
    ) {
      return false
    }
    return true
  }

  const validate = () => {
    let duplicates = []
    if (typeof data.name !== "undefined" && data.name?.trim() !== "") {
      duplicates = activities.filter(
        (x) =>
          (!!value
            ? x.name?.toLowerCase() === data.name?.trim().toLowerCase() && x.id !== value?.id
            : x.name?.toLowerCase() === data.name?.trim().toLowerCase()) && data.studyID === x.study_id
      )
      if (duplicates.length > 0) {
        enqueueSnackbar("Activity with same name already exist.", { variant: "error" })
      }
    }
    if ((value?.spec ?? "").includes("spark.survey") || activitySpecId.includes("spark.survey")) {
      const status = Object.keys(questions).length > 0 ? validateQuestions(questions) : false
      //validate ids
      const idStatus = validateIDs(questions)

      // Validate any logical expressions
      // const expressionStatus = validateExpression(questions) FIXME: this
      // const validExpressions = expressionStatus ?? true
      return (
        status &&
        !(
          typeof data.studyID == "undefined" ||
          data.studyID === null ||
          data.studyID === "" ||
          duplicates.length > 0 ||
          typeof data.name === "undefined" ||
          (typeof data.name !== "undefined" && data.name?.trim() === "")
        ) &&
        // validExpressions &&
        idStatus
      )
    } else {
      return !(
        typeof data.studyID == "undefined" ||
        data.studyID === null ||
        data.studyID === "" ||
        duplicates.length > 0 ||
        typeof data.name === "undefined" ||
        (typeof data.name !== "undefined" && data.name?.trim() === "")
      )
    }
  }

  const validateIDs = (questions) => {
    if (Object.keys(questions).length === 0) {
      return false
    }
    const regex = /^[a-zA-Z0-9]*$/
    const IDs = questions.map((s) => s.human_id)
    for (const question of questions) {
      if (question.human_id !== undefined) {
        //check id is valid through reged only accepting alphanumeric
        if (specialCharacters.test(question.human_id)) {
          const text =
            i18n.language === "nl"
              ? question.textNL === undefined
                ? question.text
                : question.textNL
              : i18n.language == "es-ES"
              ? question.textES === undefined
                ? question.text
                : question.textES
              : i18n.language == "hi-IN"
              ? question.textHI === undefined
                ? question.text
                : question.textHI
              : question.text
          enqueueSnackbar(t("Please enter valid ID for: ") + text, { variant: "error" })
          return false
        } else if (IDs.filter((x) => x === question.human_id).length > 1) {
          const text =
            i18n.language === "nl"
              ? question.textNL === undefined
                ? question.text
                : question.textNL
              : i18n.language == "es-ES"
              ? question.textES === undefined
                ? question.text
                : question.textES
              : i18n.language == "hi-IN"
              ? question.textHI === undefined
                ? question.text
                : question.textHI
              : question.text

          enqueueSnackbar(t("Please enter unique ID for: ") + text + t(", current id: ") + question.human_id, {
            variant: "error",
          })
          return false
        } else if (question.conditional_logic) {
          if (question.condition === null || question.condition === undefined || question.condition === "") {
            const text =
              i18n.language === "nl"
                ? question.textNL === undefined
                  ? question.text
                  : question.textNL
                : i18n.language == "es-ES"
                ? question.textES === undefined
                  ? question.text
                  : question.textES
                : i18n.language == "hi-IN"
                ? question.textHI === undefined
                  ? question.text
                  : question.textHI
                : question.text
            enqueueSnackbar(t("Please enter valid conditional logic for: ") + text, { variant: "error" })
            return false
          }
          //validate conditional logic

          const variables = [
            //TODO: lamp.steps or spark.steps ??
            // FIXME: system ids
            { id: undefined, human_id: "spark.steps", type: "sensor" },
            { id: undefined, human_id: "spark.gps", type: "sensor" },
            ...questions,
          ]

          if (!validateExpression([question.condition], variables)) {
            enqueueSnackbar(t("Please enter valid conditional logic for: ") + question.text, { variant: "error" })

            return false
          }
        }
      }
    }
    const listOrMulti = questions.filter((s) => s.type === "list" || s.type === "multiselect")
    for (const item of listOrMulti) {
      if (item.options !== undefined) {
        for (const option of item.options) {
          //check id is valid through reged only accepting alphanumeric
          if (specialCharacters.test(option.id)) {
            const text =
              i18n.language === "nl"
                ? option.valueNL === undefined
                  ? option.value
                  : option.valueNL
                : i18n.language == "es-ES"
                ? option.valueES === undefined
                  ? option.value
                  : option.valueES
                : i18n.language == "hi-IN"
                ? option.valueHI === undefined
                  ? option.value
                  : option.valueHI
                : option.value
            enqueueSnackbar(t("Please enter valid variable name for: ") + text, { variant: "error" })
            return false
          }
        }
      }
    }

    return true
  }

  const saveToBackend = () => {
    if (!validate()) {
      enqueueSnackbar(`${t("Please fill in all required fields.")}`, {
        variant: "error",
      })
      return
    }

    if (!!value?.id) {
      // Update existing survey
      let survey = {
        ...data,
        settings: questions,
        needs_trigger: value.needs_trigger,
        is_hidden: value.is_hidden,
      }
      SPARK.Activity.update(value.id, survey)
        .then((x) => {
          enqueueSnackbar(`${t("Successfully updated the Survey Activity.")}`, {
            variant: "success",
          })
          history.back()
        })
        .catch((err) => {
          console.dir(err)
          enqueueSnackbar(`${t("Something went wrong.")}`, {
            variant: "error",
          })
        })
    } else {
      // Create new survey
      SPARK.Activity.create(study.id, data)
        .then((x) => {
          enqueueSnackbar(`${t("Successfully created a new Survey Activity.")}`, {
            variant: "success",
          })
          history.back()
        })
        .catch((err) => {
          console.dir(err)
          enqueueSnackbar(`${t("Something went wrong.")}`, {
            variant: "error",
          })
        })
    }
  }

  // Add question type added to the questions state
  const handleAddQuestion = (type: string) => {
    console.log("Adding question of type", type)
    let questionTypeId =
      type === "group"
        ? QUESTION_GROUP_TYPE_ID
        : questionTypes[QUESTION_TYPE_KEY].fields
            .concat(questionTypes[FORM_STRUCTURE_TYPE_KEY].fields)
            .find((field) => field.type === type)?.id

    let id: number
    // Assure unique id //TODO: do this in a more structured way?
    do {
      id = Math.floor(Math.random() * 1000000)
    } while (
      questions.find((q) => q.id === id) !== undefined ||
      questions
        .map((q) => q.children ?? [])
        .flat()
        .find((q) => q.id === id) !== undefined
    )

    const newQuestion: Question = {
      id: id,
      human_id: "",
      text: "",
      type: type,
      question_type_id: questionTypeId ?? 1, //default to "text" FIXME:
      description: "",
      options: undefined,
      required: false,
      conditional_logic: false,
      condition: null,
      children: questionTypeId === QUESTION_GROUP_TYPE_ID ? [] : undefined,
    }

    questions.push(newQuestion)

    // Set active action to edit the question
    setSelectedQuestion(newQuestion)
    setShowQuestionTypePicker(false)
  }

  const handleEditQuestion = (editedQuestion: Question) => {
    setQuestions((prev) => {
      const clonedQuestions = [...prev]
      const activeQuestionIndex = prev.findIndex((q) => q.id === selectedQuestion.id)
      const activeQuestion = questions[activeQuestionIndex]

      // Question not found, search in child props
      if (activeQuestionIndex === -1) {
        const parentQuestionIndex = prev.findIndex((q) => q.children?.find((c) => c.id === selectedQuestion.id))
        const parentQuestion = prev[parentQuestionIndex]
        const childIndex = parentQuestion.children?.findIndex((c) => c.id === selectedQuestion.id)

        // When childIndex still is -1, the question could not be traced, return the previous state
        if (childIndex === -1) {
          return prev
        }

        // Update child and parent question
        const updatedChild = { ...parentQuestion.children[childIndex], ...editedQuestion }
        clonedQuestions[parentQuestionIndex].children[childIndex] = updatedChild

        return clonedQuestions
      }

      // Question is in the highest order, update accordingly
      const updatedActiveQuestion = { ...activeQuestion, ...editedQuestion }
      clonedQuestions[activeQuestionIndex] = updatedActiveQuestion

      // Handle question group type, assign / remove children property
      if (editedQuestion.question_type_id === QUESTION_GROUP_TYPE_ID) {
        clonedQuestions[activeQuestionIndex].children = editedQuestion.children ?? []
      } else {
        clonedQuestions[activeQuestionIndex].children = null
      }

      return clonedQuestions
    })

    // Refresh selected question
    setSelectedQuestion(editedQuestion)
  }

  useEffect(() => {
    // FIXME: temp fix loading data for the nice form
    if (data.settings) {
      data.settings?.map((element) => {
        for (const key in questionTypes[QUESTION_TYPE_KEY].fields) {
          if (element.type === questionTypes[QUESTION_TYPE_KEY].fields[key].type) {
            element.question_type_id = questionTypes[QUESTION_TYPE_KEY].fields[key].id
          }
        }
        for (const key in questionTypes[FORM_STRUCTURE_TYPE_KEY].fields) {
          if (element.type === questionTypes[FORM_STRUCTURE_TYPE_KEY].fields[key].type) {
            element.question_type_id = questionTypes[FORM_STRUCTURE_TYPE_KEY].fields[key].id
          }
        }
      })

      setQuestions(data.settings)
    }
  }, [data.settings])

  const deleteQuestion = (question) => {
    if (question.id === selectedQuestion?.id) setSelectedQuestion(false)

    setQuestions((prev) => {
      let clonedQuestions = [...prev]

      // Check top level questions
      clonedQuestions = clonedQuestions.filter((q) => q.id !== question.id)

      // Check child questions
      clonedQuestions.map((q) => {
        if (q?.children) {
          q.children = q?.children?.filter((c) => c.id !== question.id)
        }
        return q
      })

      return clonedQuestions
    })
  }

  const getQuestionAnswerCombinations = () => {
    return questions.map((question) => {
      return {
        human_id: question.human_id,
        type: question.type,
        options: question.options ? question.options.map((option) => option.human_id) : undefined,
      }
    })
  }

  return (
    <Grid container direction="column" style={{ height: "100%", marginTop: 60 }} spacing={2} {...props}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box px="40px" pt="30px" height="100%">
        <Grid container spacing={4} style={{ height: "100%" }}>
          {/* Questions sidebar */}
          <Grid item xs={12} md={3} style={{ padding: 0 }}>
            <QuestionsList
              questions={questions}
              setQuestions={setQuestions}
              onAddQuestion={() => setShowQuestionTypePicker(true)}
              onEditQuestion={(question: Question) => {
                setSelectedQuestion(question)
                setShowQuestionTypePicker(false)
              }}
              onEditQuestionEntry={(question: Question) => {
                handleEditQuestion(question)
              }}
              onDeleteQuestion={deleteQuestion}
              onSaveActivity={saveToBackend}
            />
          </Grid>

          {/* Main content */}
          <Grid item sm={6}>
            {showQuestionTypePicker && <AddQuestionForm onAddQuestion={handleAddQuestion} />}
            {selectedQuestion && !showQuestionTypePicker && (
              <EditQuestionForm
                onEditedQuestion={(question: Question) => {
                  handleEditQuestion(question)
                  setSelectedQuestion(false)
                }}
                onEditedQuestionEntry={(question: Question) => {
                  handleEditQuestion(question)
                }}
                question={selectedQuestion}
              />
            )}
          </Grid>

          {/* Question settings */}
          <Grid item sm={3}>
            {selectedQuestion && !showQuestionTypePicker && (
              <QuestionItemSettings
                question={selectedQuestion}
                onChange={handleEditQuestion}
                questions={getQuestionAnswerCombinations()}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}
