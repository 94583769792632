// Core Imports
import { Box, Grid, Icon, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import schema from "../../adaptable/theme.json"
import { ReactComponent as EditIcon } from "../../icons/app/edit-02.svg"
import { ReactComponent as SurveyIcon } from "../../icons/app/file-02.svg"
import { ReactComponent as SurveyDoneIcon } from "../../icons/app/file-check-02.svg"
import ActivityPopup from "./ActivityPopup"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blankMsg: {
      marginBottom: "15px",
      marginTop: "5px",
      "& path": { fill: "#666" },
      "& p": { margin: "2px 5px" },
    },
    surveyCard: {
      background: "#fff",
      margin: "10px 8px 0px 8px",
      borderRadius: 8,
      height: 68,
      padding: 20,
      border: "1px solid #eaecf0",
    },
    cardContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    iconBox: {
      margin: 10,
      height: 32,
      width: 36,
      padding: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
    },
    icon: {
      width: 18,
      height: 18,
      color: "#fff",
    },
    descriptionBox: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginLeft: 10,
    },
    descriptionTitle: {
      fontSize: "0.8rem",
    },
    descriptionSubtitle: {
      fontSize: "0.7rem",
    },
    actionIcon: {
      marginLeft: "auto",
      marginRight: 18,
    },
  })
)

export default function SurveyList({ type, savedActivities, tag, participant, showStreak, ...props }) {
  const classes = useStyles()
  const [activity, setActivity] = useState(null)
  const [open, setOpen] = useState(false)
  const [questionCount, setQuestionCount] = React.useState(0)
  const { t } = useTranslation()

  const handleClickOpen = (y: any) => {
    window.location.href = `/#/participant/${participant?.id}/activity/${y?.id}?mode=dashboard`
    // SPARK.Activity.view(y.id).then((data) => {
    //   setActivity(data)
    //   setOpen(true)
    //   y.spec === "spark.dbt_diary_card"
    //     ? setQuestionCount(7)
    //     : y.spec === "spark.survey"
    //     ? setQuestionCount(data.settings?.length ?? 0)
    //     : setQuestionCount(0)
    // })
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {savedActivities.length
          ? savedActivities.map((activity) => (
              <SurveyCard activity={activity} handleClick={handleClickOpen} participantId={participant.id} />
            ))
          : type !== "Portal" && (
              <Box display="flex" className={classes.blankMsg} ml={1}>
                <Icon>info</Icon>
                <p>{`${t("There are no " + type + " activities available.")}`}</p>
              </Box>
            )}
      </Grid>
      <ActivityPopup
        activity={activity}
        tag={tag}
        questionCount={questionCount}
        open={open}
        onClose={() => setOpen(false)}
        type={type}
        showStreak={showStreak}
        participant={participant}
      />
    </Box>
  )
}

const SurveyCard = ({ activity, handleClick, participantId, ...props }) => {
  const classes = useStyles()

  // TODO: if scheduled activity, show time left in card
  return (
    <Grid
      item
      xs={12}
      className={classes.surveyCard}
      onClick={() => {
        handleClick(activity)
      }}
    >
      <Box className={classes.cardContainer}>
        <Box
          className={classes.iconBox}
          style={{ background: activity.done ? schema.palette.primary.success : schema.palette.primary.main }}
        >
          {activity.done ? <SurveyDoneIcon className={classes.icon} /> : <SurveyIcon className={classes.icon} />}
        </Box>

        <Box className={classes.descriptionBox}>
          <Typography variant="h6" className={classes.descriptionTitle}>
            {activity.name}
          </Typography>
          {activity.timestamp && (
            <Typography variant="subtitle1" className={classes.descriptionSubtitle}>
              {new Date(activity.timestamp).toLocaleDateString()}
            </Typography>
          )}
        </Box>

        {activity.done && <EditIcon className={classes.actionIcon} />}
      </Box>
    </Grid>
  )
}
