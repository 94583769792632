// Core Imports
import { Backdrop, Box, CircularProgress, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import SPARK from "spark-core"
import { Service } from "../DBService/DBService"
import AssessSurveyList from "./AssessSurveyList"
import NotificationList from "./NotificationList"
import SurveyList from "./SurveyList"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    headerTitle: {
      textTransform: "capitalize",
      paddingTop: 0,
      fontWeight: 500,
      marginTop: 20,
      marginBottom: 8,
    },
    thumbContainer: {
      padding: "0px 8px 0px 8px",
      maxWidth: 1055,
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        paddingBottom: 80,
      },
    },
  })
)

export const games = [
  "spark.jewels_a",
  "spark.jewels_b",
  "spark.spatial_span",
  "spark.cats_and_dogs",
  "spark.pop_the_bubbles",
  "spark.balloon_risk",
]

export default function Home({ participant, activities, tab, ...props }) {
  const classes = useStyles()
  const [tag, setTag] = useState([])
  const [savedActivities, setSavedActivities] = useState([])
  const [loading, setLoading] = useState(true)

  const [notifications, setNotifications] = useState([])

  React.useEffect(() => {
    // TODO: fill with real notifications
    setNotifications([
      // {title: "Notification1", description: "some long description some long description some long description"},
      // {title: "Notification2", description: "some long description some long description some long description"}
    ])
  }, [])

  const handleLoadActivities = async () => {
    setLoading(true)
    var gActivities = (activities || []).filter(
      (x) =>
        ((games.includes(x.spec) ||
          x.spec === "spark.group" ||
          x.spec === "spark.dbt_diary_card" ||
          x.spec === "spark.recording" ||
          x.spec === "spark.survey") &&
          (typeof x?.category === "undefined" || x?.category === null)) ||
        (!!x?.category && !x?.category.includes("outOfDate"))
    )

    await Promise.all(
      gActivities.map(async (a) => {
        const res = await SPARK.ActivityEvent.allByParticipant(participant.id, a.id)
        // Whether this participant has performed this activity yet
        a.done = res && res.length > 0
      })
    )

    setSavedActivities(gActivities)

    if (gActivities.length > 0) {
      Service.getAllTags("activitytags").then((data) => {
        setTag(
          (data || []).filter(
            (x) =>
              ((games.includes(x.spec) ||
                x.spec === "spark.group" ||
                x.spec === "spark.dbt_diary_card" ||
                x.spec === "spark.recording" ||
                x.spec === "spark.survey") &&
                (typeof x?.category === "undefined" || x?.category === null)) ||
              !!x?.category
          )
        )
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleLoadActivities()
  }, [])

  return (
    <Box className={classes.thumbContainer}>
      {notifications.length > 0 && (
        <Box mt={8}>
          <NotificationList notifications={notifications} />
        </Box>
      )}

      <Box mx={1}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography variant="h5" className={classes.headerTitle}>
          {"Open Survey"}
        </Typography>

        {tab === "home" && (
          <SurveyList
            participant={participant}
            savedActivities={savedActivities}
            tag={tag}
            showStreak={() => console.log("no streak implemented")}
            type="Assess"
          />
        )}
        {tab === "assess" && (
          <AssessSurveyList
            participant={participant}
            savedActivities={savedActivities}
            tag={tag}
            showStreak={() => console.log("no streak implemented")}
            type="Assess"
          />
        )}
      </Box>
    </Box>
  )
}
