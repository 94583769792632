// Core Imports
import { Box, Grid, createStyles, makeStyles, Theme, Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbContainer: {
      padding: "0px 16px 0px 16px",
      maxWidth: 1055,
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        paddingBottom: 80,
      },
    },
    surveyCard: {
      background: "#fff",
      borderRadius: 8,
      height: 68,
      padding: 20,
      border: "1px solid #eaecf0",
    },
    cardContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    descriptionTitle: {
      fontSize: "0.8rem",
    },
  })
)

export default function Settings({ participant, activeTab, ...props }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleOpenPermissions = () => {
    activeTab("permissions")
  }

  return (
    <Box className={classes.thumbContainer}>
      <Typography
        variant="h5"
        style={{
          textTransform: "capitalize",
          paddingTop: 0,
          fontWeight: 500,
          marginTop: 20,
          marginBottom: 8,
        }}
      >
        {t("Settings")}
      </Typography>

      <SettingsItem onClick={handleOpenPermissions} />
    </Box>
  )
}

const SettingsItem = ({ onClick, ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid item xs={12} className={classes.surveyCard} onClick={onClick}>
      <Box className={classes.cardContainer}>
        <Typography variant="h6" className={classes.descriptionTitle}>
          {t("Check permissions")}
        </Typography>
      </Box>
    </Grid>
  )
}
