// Core Imports
import { Backdrop, Box, CircularProgress, makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import { sensorEventUpdate } from "./Participant/BottomMenu"
import { Service } from "./DBService/DBService"
import EmbeddedActivity from "./Participant/EmbeddedActivity"
import {
  fetchActivityTriggers,
  spliceActivity,
  spliceCTActivity,
} from "./Researcher/ProjectView/ActivityList/ActivityMethods"
import { evaluateExpression } from "./Researcher/ProjectView/ActivityList/ExpressionMethods"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 20,
  },
  MuiDialogPaperScrollPaper: {
    maxHeight: "100% !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))

export default function GroupActivity({ participant, activity, noBack, tab, ...props }) {
  const classes = useStyles()
  const [currentActivity, setCurrentActivity] = useState(null)
  const [groupActivities, setGroupActivities] = useState([])
  const [startTime, setStartTime] = useState(new Date().getTime())
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const [index, setIndex] = useState(-1)
  const [response, setResponse] = useState([])
  const [triggers, setTriggers] = useState(null)

  useEffect(() => {
    if (index === 0) {
      sensorEventUpdate(tab?.toLowerCase() ?? null, participant?.id ?? participant, activity.id)
    }
    if ((groupActivities || []).length > 0 && index <= (groupActivities || []).length - 1) {
      setLoading(true)
      const activityId = groupActivities[index]
      SPARK.Activity.view(activityId).then((activity) => {
        // Check any conditions set on this activity, launch the activity if met
        if (triggers !== null) {
          if (conditionsMet(activity)) {
            Service.getUserDataByKey("activitytags", [activity?.id], "id").then((data) => {
              const tag = data[0]
              const dataActivity =
                activity.spec === "spark.survey"
                  ? spliceActivity({ raw: activity, tag })
                  : spliceCTActivity({ raw: activity, tag })
              setCurrentActivity(dataActivity)
              setLoading(false)
            })
          } else {
            iterateActivity()
          }
        }
      })
    }
  }, [index, triggers])

  useEffect(() => {
    if (groupActivities.length > 0) setIndex(0)
  }, [groupActivities])

  useEffect(() => {
    SPARK.Activity.view(activity.id).then((data) => {
      setIndex(-1)
      setGroupActivities(data.settings)
    })
    fetchActivityTriggers(activity.id).then((x) => {
      setTriggers(x.data)
    })
  }, [])

  useEffect(() => {
    if (index >= 0 && currentActivity !== null) {
      setLoading(true)
      iterateActivity()
    }
  }, [response])

  const iterateActivity = () => {
    let val = index + 1
    setCurrentActivity(null)
    setIndex(val)
    if (groupActivities.length === val) {
      SPARK.ActivityEvent.create(participant.id ?? participant, {
        timestamp: new Date().getTime(),
        duration: new Date().getTime() - startTime,
        activity: activity.id,
        static_data: {},
      })
      props.onComplete({ timestamp: new Date().getTime() })
    }
  }

  const conditionsMet = (activity) => {
    const triggersToCheck = triggers.filter((x) => x.trigger === activity.id)
    if (triggersToCheck.length > 0) {
      // TODO: this activity could be optional,
      // so check the conditions of the triggers
      for (const trigger of triggersToCheck) {
        switch (trigger.name) {
          // TODO: support multiple trigger types (other than question.trigger)
          case "question.trigger":
            return evaluateExpression(response, trigger.conditions)
          default:
            console.log("?", response)
        }
      }
    }
    return true
  }

  const saveResponse = (r) => {
    // FIXME: check activity spec?
    const currentSettings = currentActivity.settings[0]

    if (!r) {
      if (index > 0) {
        setIndex(index - 1)
      } else {
        history.back()
      }
      return
    }

    const currentQuestion = currentSettings.human_id
    const questionType = currentSettings.type

    // TODO: Is this generic enough?
    r["human_id"] = currentQuestion
    r["question_type"] = questionType

    if (currentSettings.options) {
      // Save options, need this when checking conditions
      r["options"] = currentSettings.options.map((option) => ({
        question_id: currentQuestion,
        answer_id: option.id,
        value: option.value,
      }))
    }

    setResponse(response.concat(r))
  }

  return (
    <div style={{ height: "100%" }}>
      {!!currentActivity && (
        <Box>
          <EmbeddedActivity
            name={currentActivity?.name}
            activity={currentActivity}
            participant={participant}
            onComplete={(a) => {
              saveResponse(a)
            }}
            noBack={noBack}
            tab={tab}
          />
        </Box>
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
