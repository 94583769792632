// Core Imports
import { Box, Grid, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import SPARK from "spark-core"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import schema from "../../adaptable/theme.json"
import { ReactComponent as MessageIcon } from "../../icons/app/notification-message.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blankMsg: {
      marginBottom: "15px",
      marginTop: "5px",
      "& path": { fill: "#666" },
      "& p": { margin: "2px 5px" },
    },
    surveyCard: {
      background: "#fff",
      margin: "8px 8px 0px 8px",
      borderRadius: 8,
      height: 58,
      padding: 20,
      border: "1px solid #eaecf0",
    },
    cardContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    iconBox: {
      margin: 10,
      height: 32,
      width: 36,
      padding: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
    },
    icon: {
      width: 18,
      height: 18,
      color: "#fff",
    },
    descriptionBox: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginLeft: 10,
    },
    descriptionTitle: {
      fontSize: "0.8rem",
    },
    descriptionSubtitle: {
      fontSize: "0.7rem",
      width: 240,
    },
    actionIcon: {
      // margin: 30,
      marginLeft: "auto",
      marginRight: 18,
    },
  })
)

export default function NotificationList({ notifications, ...props }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [questionCount, setQuestionCount] = React.useState(0)
  const { t } = useTranslation()

  const handleClickOpen = (y: any) => {
    console.log("does nothing yet")
  }

  return (
    <Box>
      <Grid container spacing={1}>
        {notifications.map((notification) => (
          <NotificationCard notification={notification} handleClick={handleClickOpen} />
        ))}
      </Grid>
    </Box>
  )
}

const NotificationCard = ({ notification, handleClick, ...props }) => {
  const classes = useStyles()

  return (
    <Grid
      item
      xs={12}
      className={classes.surveyCard}
      onClick={() => {
        handleClick(notification)
      }}
    >
      <Box className={classes.cardContainer}>
        <Box className={classes.iconBox} style={{ background: schema.palette.primary.main }}>
          <MessageIcon className={classes.icon} />
        </Box>

        <Box className={classes.descriptionBox}>
          <Typography variant="h6" className={classes.descriptionTitle}>
            {notification.title}
          </Typography>
          {notification.description && (
            <Typography noWrap variant="subtitle1" className={classes.descriptionSubtitle}>
              {notification.description}
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  )
}
