import {
  BottomNavigationAction,
  Box,
  Drawer,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"

import { ReactComponent as Home } from "../../icons/app/home-05.svg"
import { ReactComponent as Assess } from "../../icons/app/list.svg"
import { ReactComponent as Settings } from "../../icons/app/settings-02.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigation: {
      "& svg": { opacity: 0.5 },
      "& span": { color: "black", fontSize: 12 },
      fontsize: "1px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "rgba(255, 255, 255, 0.75)",
    },
    navigationSelected: {
      "& svg": { opacity: 1 },
      "& span": { color: "black", fontSize: 12 },
      fontWeight: 500,
    },
    navigationLabel: {
      letterSpacing: 0,
    },
    navigationIcon: { height: 28, width: 28, marginBottom: 2 },
    leftbar: {
      "& > div": {
        [theme.breakpoints.up("md")]: {
          backgroundColor: "#fff",
          border: 0,
        },
        "& a": {
          [theme.breakpoints.down("sm")]: {
            flex: 1,
          },
        },
      },
    },
    logResearcher: {
      "& > div": {
        marginTop: 50,
        height: "calc(100vh - 55px)",
        borderLeft: theme.palette.primary.main + " solid 5px",
        zIndex: 1111,
        [theme.breakpoints.down("sm")]: {
          borderBottom: theme.palette.primary.main + " solid 5px",
          borderRight: theme.palette.primary.main + " solid 5px",
        },
      },
    },
  })
)

export async function sensorEventUpdate(val: string, participantId: string, activityId: string, timestamp?: number) {
  if (SPARK.Auth._type === "participant") {
    return await SPARK.SensorEvent.create(participantId, {
      timestamp: timestamp ?? new Date().getTime(),
      sensor: "spark.analytics",
      data: {
        type: "open_page",
        page: val,
        activity: activityId,
      },
    })
  }
}

export default function BottomMenu({ ...props }) {
  const classes = useStyles()
  const supportsSidebar = useMediaQuery(useTheme().breakpoints.up("md"))
  const { t } = useTranslation()
  const tabs = [
    {
      id: 0,
      name: "home",
      label: "Home",
      classes: {
        root: classes.navigation,
        selected: classes.navigationSelected,
        label: classes.navigationLabel,
      },
      icon: <Home className={classes.navigationIcon} />,
    },
    {
      id: 1,
      name: "assess",
      label: "Assess",
      classes: {
        root: classes.navigation,
        selected: classes.navigationSelected,
        label: classes.navigationLabel,
      },
      icon: <Assess className={classes.navigationIcon} />,
    },
    {
      id: 2,
      name: "settings",
      label: "Settings",
      classes: {
        root: classes.navigation,
        selected: classes.navigationSelected,
        label: classes.navigationLabel,
      },
      icon: <Settings className={classes.navigationIcon} />,
    },
  ]

  const getTab = (tab: string) => {
    if (tab === "permissions") return
    return tabs.find((item) => item.name === tab).id
  }
  const [openTab, _setTab] = useState(getTab(props.tabValue))
  const [tabValues, setTabValues] = useState(
    localStorage.getItem("bottom-menu-tabs" + props.participant.id) !== null
      ? JSON.parse(localStorage.getItem("bottom-menu-tabs" + props.participant.id))
      : []
  )

  useEffect(() => {
    sensorEventUpdate(tabs[openTab].name, props.participant.id, null)
    props.activeTab(tabs[openTab].name, props.participant.id)
  }, [])

  const openTabUpdate = (val) => {
    sensorEventUpdate(tabs[val].name, props.participant.id, null)
    props.activeTab(tabs[val].name, props.participant.id)
    _setTab(val)
    props.setShowDemoMessage(false)
  }

  const updateLocalStorage = (tab) => {
    setTabValues({ ...tabValues, [tab]: false })
  }

  useEffect(() => {
    localStorage.setItem("bottom-menu-tabs" + props.participant.id, JSON.stringify(tabValues))
  }, [tabValues])

  return (
    <div>
      <Box clone displayPrint="none">
        <Drawer
          open
          className={classes.leftbar + (SPARK.Auth._type === "participant" ? "" : " " + classes.logResearcher)}
          anchor={supportsSidebar ? "top" : "bottom"}
          variant="permanent"
          PaperProps={{
            style: {
              padding: "0px 30px 0px 30px",
              flexDirection: "row",
              justifyContent: !supportsSidebar ? "center" : undefined,
              width: "100%",
            },
          }}
        >
          {(tabs ?? []).map((_, index) => (
            <BottomNavigationAction
              showLabel
              selected={openTab === index}
              icon={tabs[index].icon}
              label={`${t(tabs[index].label)}`}
              value={index}
              classes={tabs[index].classes}
              onChange={(_, newTab) => openTabUpdate(newTab)}
            />
          ))}
        </Drawer>
      </Box>
    </div>
  )
}
