// Core Imports
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  styled,
} from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
// Local Imports
import { useTranslation } from "react-i18next"
import SPARK, { Participant as ParticipantObj } from "spark-core"
import schema from "../../adaptable/theme.json"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    gridCenter: {
      display: "flex",
      alignItems: "center",
    },
    permissionLabel: {
      color: schema.palette.primary.main,
      fontWeight: 500,
      fontSize: 17,
      textDecoration: "underline",
    },
    containerBottom: {
      marginTop: 40,
      padding: 20,
      display: "flex",
      alignItems: "center",
      borderRadius: 8,
      border: "2px solid #EAECF0",
    },
    nextbtn: {
      position: "absolute",
      marginTop: 20,
      right: 20,
      background: "#EE9142",
      borderRadius: "8px",
      width: "88px",
      "& span": {
        color: "#fff",
      },
      height: 44,
      fontWeight: 600,
      boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
      textTransform: "capitalize",
      fontSize: "16px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#EE9142",
        boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
      },
    },
    backbtn: {
      position: "absolute",
      marginTop: 20,
      left: 20,
      background: "#fff",
      borderRadius: "8px",
      border: "1px solid #000",
      width: "88px",
      "& span": {
        color: "#000",
      },
      height: 44,
      fontWeight: 600,
      boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
      textTransform: "capitalize",
      fontSize: "16px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#fff",
        boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
      },
    },
  })
)

const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-thumb": {
    color: "#fff", // Thumb color
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    color: schema.palette.primary.main, // Thumb color when checked
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: schema.palette.primary.secondary, // Track color when checked,
    border: "1px solid " + schema.palette.primary.main,
  },
}))

export default function Permissions({
  participant,
  onComplete,
  ...props
}: {
  participant: ParticipantObj
  onComplete: Function
}) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const permissions = ["general_terms", "privacy_policy", "tracking_usage"]

  const name = participant?.id ?? "Bob"

  const [selected, setSelected] = useState({
    general_terms: false,
    privacy_policy: false,
    tracking_usage: false,
  })

  useEffect(() => {
    setLoading(true)
    SPARK.Participant.view(participant.id).then((res) => {
      if (!!res.permissions) {
        setSelected(res.permissions)
      }

      setLoading(false)
    })
  }, [])

  const handleToggle = (key, value) => {
    const updatedPermissions = { ...selected, [key]: value }
    setSelected(updatedPermissions)
  }

  const handleConfirm = () => {
    SPARK.Participant.updatePermissions(participant.id, selected).then((res: any) => {
      if (res.error)
        enqueueSnackbar(`${t("Something went wrong when setting your permissions.")}`, { variant: "error" })
      else {
        onComplete()
      }
    })
  }

  const handleCancel = () => {
    onComplete()
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container
        style={{
          paddingTop: 20,
          height: "100%",
        }}
      >
        <Typography variant={"subtitle2"}>
          Welkom, {name}! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua.
        </Typography>

        <FormControl
          component="fieldset"
          style={{
            marginTop: 40,
            height: "100%",
          }}
        >
          <Box display="flex" flexDirection="column" gridGap={10}>
            {permissions.map((key) => (
              <FormControlLabel
                key={key}
                control={<></>} // Empty control because we will manage layout ourselves
                label={
                  <Grid container>
                    <Grid item xs={3} className={classes.gridCenter} style={{ paddingLeft: 10 }}>
                      <StyledSwitch
                        checked={selected[key]}
                        onChange={(event) => handleToggle(key, event.target.checked)}
                      />
                    </Grid>

                    <Grid item xs={9} className={classes.gridCenter}>
                      <Typography variant="subtitle1" className={classes.permissionLabel}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} />

                    <Grid item xs={9}>
                      <Typography variant="body1">
                        {"Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            ))}
          </Box>
        </FormControl>

        <Box className={classes.containerBottom}>
          <StyledSwitch
            onChange={(e) => {
              if (e.target.checked) {
                setSelected({
                  general_terms: true,
                  privacy_policy: true,
                  tracking_usage: true,
                })
              }
            }}
          />

          <Typography variant="body1" style={{ paddingLeft: 10 }}>
            {t("U hebt alles hierboven gelezen en gaat met alles akkoord")}
          </Typography>
        </Box>

        <Fab onClick={handleCancel} className={classes.backbtn}>
          {t("Cancel")}
        </Fab>
        <Fab onClick={handleConfirm} className={classes.nextbtn}>
          {t("Confirm")}
        </Fab>
      </Container>
    </>
  )
}
