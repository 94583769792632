import { Box, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import AddButton from "./AddButton"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& h5": {
        fontSize: "30px",
        fontWeight: "bold",
      },
    },
    optionsMain: {
      background: "#ECF4FF",
      borderTop: "1px solid #C7C7C7",

      marginTop: 20,
      width: "99.4vw",
      position: "relative",
      left: "50%",
      right: "50%",
      marginLeft: "-50vw",
      marginRight: "-50vw",
    },
    optionsSub: { width: 1030, maxWidth: "80%", margin: "0 auto", padding: "10px 0" },
  })
)

export default function Header({ projectId, selectedParticipants, searchData, setParticipants, mode, ...props }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [showFilterStudies, setShowFilterStudies] = useState(false)

  const handleShowFilterStudies = (status) => {
    setShowFilterStudies(status)
  }

  return (
    <Box className={classes.header}>
      <Typography variant="h5">{`${t("Users")}`}</Typography>
      <AddButton projectId={projectId} setParticipants={setParticipants} mode={mode} />
    </Box>
  )
}
