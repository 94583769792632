import { Box, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      "& h5": {
        fontSize: "30px",
        fontWeight: "bold",
      },
      backgroundColor: "#F9FAFB",
    },
  })
)
export default function Header({ title, ...props }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.header}>
      <Typography variant="h5">{title}</Typography>
    </Box>
  )
}
